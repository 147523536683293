"use strict"

import * as universal from '@/lib/core/universal'
import * as jsf from '@/lib/js_functions'
import { linkDataVersionsManager, fieldsToUpdate, updateInfo } from '@/lib/core/linkDataVersionsManager'
import { map } from '@/lib/core/components/data/form/map'
import { vtos } from '@/lib/core/public/vtos'
import { iptus } from '@/lib/core/public/iptus'
import { multilines } from '@/lib/core/components/data/form/multilines'
import { dialog } from '@/lib/core/components/dialog'
import { Poligono } from '@/lib/core/maps/poligono'

export class lotes {
  #register
  #fields
  #newQuadraSelect
  #map
  #_LDVM
  #lotesCurrentDataId
  #lotesLastDataId
  #lotesCurrentLabel
  #lotesLastLabel
  #_iptus
  #_vtos
  #lotesPolygonsOptions
  #polygonsAux
  #polygonsOnMap
  #newLotesMultilines
  #newLotesMap
  #newFieldsToUpdate
  #updateLotesLinks
  constructor({ register, fields, newQuadraSelect, map }) {
    this.#register = register
    this.#fields = fields
    this.#newQuadraSelect = newQuadraSelect
    this.#map = map
    this.#_LDVM = new linkDataVersionsManager({ company: 'public', dataVersionName: 'lotes' })
    this.#lotesCurrentDataId = this.#_LDVM?.getCurrentDataId({ register })
    this.#lotesLastDataId = this.#_LDVM?.getLastDataId()
    this.#lotesCurrentLabel = this.#_LDVM?.getVersion({ position: 'dataId', dataId: this.#lotesCurrentDataId }).label
    this.#lotesLastLabel = this.#_LDVM?.getVersion({ position: 'dataId', dataId: this.#lotesLastDataId }).label
    this.#_LDVM.setLinkDataVersion({ register, versionDataId: this.#lotesCurrentDataId })
    this.#_iptus = new iptus()
    this.#_iptus.setLinkDataVersion({ register })
    this.#_vtos = new vtos()
    this.#_vtos.setLinkDataVersion({ register })
    this.#lotesPolygonsOptions = (type) => { return {
      selected: { fillOpacity: 0.0, strokeColor: "#FF0000", strokeOpacity: 1.0, strokeWeight: 2, zIndex: 10, clickable: true },
      selectedAux: { fillOpacity: 0.0, strokeColor: "#5cb55e", strokeOpacity: 1.0, strokeWeight: 2, zIndex: 10, clickable: true },
      notSelected: { fillColor: "#CCCCCC", fillOpacity: 0.2, strokeColor: "#666666", strokeOpacity: 0.8, strokeWeight: 0.4, zIndex: 1, clickable: true },
      conflict: { fillOpacity: 0.0, strokeColor: "#00FFFF", strokeOpacity: 1.0, strokeWeight: 2, clickable: true, zIndex: 1 }
    }[type] }
    this.#polygonsAux = []
    this.#polygonsOnMap = {
      lotes: [],
      conflitos: []
    }
    this.build = this.build.bind(this)
    this.updateLotesLinks = this.updateLotesLinks.bind(this)
  }
  get polygonsAux() { return this.#polygonsAux }
  get iptus() { return this.#_iptus }
  get lotesCurrentDataId() { return this.#lotesCurrentDataId }
  get lotesLastDataId() { return this.#lotesLastDataId }
  get register() { return this.#register }
  get LDVM() { return this.#_LDVM }
  get newLotesMap() { return this.#newLotesMap }
  get newLotesMultilines() { return this.#newLotesMultilines }
  build = async () => {
    this.#newLotesMultilines = await (new lotesMultilines({ register: this.#register, fields: this.#fields, dataId: this.#lotesCurrentDataId, lastDataId: this.#lotesLastDataId, newQuadraSelect: this.#newQuadraSelect, lotesCurrentDataId: this.#lotesCurrentDataId, iptus: this.#_iptus })).build({ linkDataVersionsManager: this.#_LDVM })
    this.#newLotesMap = await (new lotesMap({ fields: this.#fields, poligono: this.#register.poligono, newLotesMultilines: this.#newLotesMultilines }))
    this.#newLotesMap.map = jsf.getJSONObject({ fields: this.#fields, name: 'poligono' }).component.map
    this.#newFieldsToUpdate = new fieldsToUpdate({ fields: [this.#newLotesMultilines, this.#newLotesMap] })
    this.#updateLotesLinks = jsf.getJSONObject({ fields: this.#fields, name: 'updateLotesLinks' })
    this.#updateLotesLinks.show = !this.#newFieldsToUpdate.checkAll('isDataIdUpdated')
    return this
  }
  updateLotesLinks = async function() {
    const { openDialog, closeDialog } = window
    const updateDataIdDialog = new dialog({ data: {
      title: 'É possível atualizar os lotes!',
      text: `Os lotes vinculados a este registro estão na versão '${this.#lotesCurrentLabel}' e podem ser atualizados para aversão '${this.#lotesLastLabel}'. Deseja fazer a atualização?`,
      buttonYes: 'Sim, pode atualizar!',
      buttonNo: 'Não atualizar',
      action: async () => {
        this.#_LDVM.updateLinkVersion({ register: this.#register })
        this.#_iptus.updateLinkDataVersionToLast({ register: this.#register })
        this.#lotesCurrentDataId = this.#_LDVM.getCurrentDataId({ register: this.#register })
        await this.#newFieldsToUpdate.updateAllDataId()
        this.#updateLotesLinks.show = false
        window.showSuccess({ text: this.#newFieldsToUpdate.sucessUpdatedAllText() || 'Os lotes foram atualizados.', dismissible: true }),
        window.closeDialog()
      },
    }, openDialog, closeDialog })
    const cannotUpdateDataIdDialog = new dialog({ data: {
      title: 'Problemas na atualização dos lotes!',
      text: this.#newFieldsToUpdate.cannotUpdateAllText(),
      buttonYes: 'Selecionar',
      buttonNo: 'Cancelar',
      action: async () => {
        window.showOverlay()
        window.closeDialog()
        const { tipoQuadra, setor, quadra } = this.getQuadraInfo()
        const lotes = (await this.getLotes({ dataId: this.#lotesLastDataId, query: { tipoQuadra, setor, quadra }, project: { lote: 1, condominio: 1, poligono: 1, sqlc: 1 } }))
          .map(lote => new LotePoligono({
            register: this.#register,
            fields: this.#fields,
            polygonsAux: this.#polygonsAux,
            lotesCurrentDataId: this.#lotesCurrentDataId,
            lotesLastDataId: this.#lotesLastDataId,
            LDVM: this.#_LDVM,
            newQuadraSelect: this.#newQuadraSelect,
            newLotesMultilines: this.#newLotesMultilines,
            iptus: this.#_iptus,
            data: lote,
            coords: lote.poligono?.[0] || [],
            lotesPolygonsOptions: this.#lotesPolygonsOptions,
            updateInfo: this.#newLotesMultilines.updateInfo
          }))
        this.#newLotesMap.openMapAux({ mainTitle: `Como está hoje (${this.#lotesCurrentLabel})`, auxTitle: `Como vai ficar (${this.#lotesLastLabel})` })
        this.#polygonsAux.clear()
        lotes.filter(lote => this.#newLotesMultilines.updateInfo.some(info => info.data?._id == lote.data._id)).map(lote => this.#polygonsAux.push(lote.data))
        const newLotesMapAux = await (new lotesMap({ fields: this.#fields, div: document.getElementById('divMapAux'), poligono: lotes, newLotesMultilines: this.#newLotesMultilines }))
        newLotesMapAux.sync(this.#map.component.map)
        newLotesMapAux.insertAll()
        window.hideOverlay()
      },
    }, openDialog, closeDialog })
    this.#newFieldsToUpdate.checkAll('isDataIdUpdated')
      ? window.showSuccess({ text: `Os lotes já estão atualizados!`, dismissible: true })
      : await this.#newFieldsToUpdate.checkAll('canUpdateDataId')
        ? updateDataIdDialog.open()
        : cannotUpdateDataIdDialog.open()
  }
  updateLotesAux = async function() {
    window.showOverlay()
    this.#_LDVM.updateLinkVersion({ register: this.#register })
    this.#_iptus.updateLinkDataVersionToLast({ register: this.#register })
    this.#lotesCurrentDataId = this.#_LDVM.getCurrentDataId({ register: this.#register })
    this.#newLotesMultilines.lotesCurrentDataId = this.#lotesCurrentDataId
    await this.#newLotesMultilines.updateLines({ lines: this.#polygonsAux })
    this.#newLotesMap.closeMapAux()
    this.#updateLotesLinks.show = false
    window.showSuccess({ text: `Os lotes foram atualizados com sucesso! Ainda é necessário gravar o registro.`, dismissible: true })
    window.hideOverlay()
  }
  async showTerrenosConflitadosInMap(dataId) {
    if (!this.conditionalShowOnMap('conflitos')) { return }
    if (!this.#register.link_quadra) { return window.$showAlert({ type: 'warning', text: 'Selecione uma quadra!', dismissible: true }) }
    const _terrenosAtivosNaQuadra = await this.#getTerrenosConflitados()
    console.log({ _terrenosAtivosNaQuadra })
    const _terrenosAtivosNaQuadraReplaced = await universal.replaceAllLinks({ company: window.company, originalData: _terrenosAtivosNaQuadra, dataId: dataId || 'terrenos' })
    const terrenosAtivosNaQuadra = _terrenosAtivosNaQuadraReplaced?.filter(terreno => terreno.link_status != 'Descartado')
    if (!terrenosAtivosNaQuadra || terrenosAtivosNaQuadra.length == 0) { return window.$showAlert({ type: 'warning', text: 'Nenhum conflito encontrado!', dismissible: true }) }
    this.#showTerrenosInMap({ terrenos: terrenosAtivosNaQuadra })
  }
  async #getTerrenosConflitados() { // eslint-disable-line
    return (await universal.getGenericRegisters({ dataId: 'terrenos_all', query: { link_quadra: this.#register.link_quadra, link_status: { $nin: ['Descartado', 'Landbank'] }, _id: { $ne: this.#register._id } }, project: { _id: 1, responsaveis: 1, poligono: 1, nome: 1, status: 1, link_status: 1, historico: 1 } })).data
  }
  #showTerrenosInMap({ terrenos }) { // eslint-disable-line
    terrenos?.map(terreno => {
      if (terreno._id !== this.#register._id) { terreno.poligono.map(poligono => {
        this.#polygonsOnMap.conflitos.push(
          this.#newLotesMap.insertObject({ coords: poligono.poligono ? poligono.poligono : poligono, options: () => this.#lotesPolygonsOptions('Polygon') })
        )
      })}
    })
  }
  setColorStatusOnPoligonoLote = async function({ status, link_status, poligono, link_lote }) {
    const statusLote = await this.getColorStatusLote({ status })
    this.setColorOnLote({ link_status, color: statusLote[0].color, poligono, link_lote })
  }
  calculeTestadas = function() {
    const _testadas = this.#register.testadas
    this.#register.testadas = []
    const _lotes = this.#register.lotes.filter(lote => lote.codlog != undefined)
    const getLogradouro = async (codlog) => {
      const _testada = _testadas.find(testada => testada.codlog == codlog)
      if (_testada && _testada.logradouro) { return _testada.logradouro }
      const iptuCurrentDataId = (new iptus()).getCurrentDataId({ register: this.#register })
      return (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: iptuCurrentDataId, action: 'read/one', query: { ["CODLOG DO IMOVEL"]: codlog }, project: { "NOME DE LOGRADOURO DO IMOVEL": 1 } }), returnData: true, showAlert: false }))?.data?.["NOME DE LOGRADOURO DO IMOVEL"]
    }
    const sumTestadas = (codlog) => jsf.numberOut(_lotes.reduce((sum, lote) => sum += lote.codlog == codlog ? jsf.numberIn(lote.testada) : 0, 0), 2, "0,00")
    jsf.executeIfUniq({ array: _lotes, prop: "codlog", func: async (lote) => { this.#register.testadas.push({ logradouro: await getLogradouro(lote.codlog), codlog: lote.codlog, testada: sumTestadas(lote.codlog) }) } })
  }
  reCalculeFieldsByChangeLotes = function() {
    new iptus().calculeSumAreaIPTU({ register: this.#register, fields: this.#fields })
    new vtos().calculeMaxVTO({ fields: this.#fields, lotes: this.#register.lotes })
    // this.calculeTestadas(register)
  }
  removerPoligonoLote = function({ poligono, link_lote }) {
    const index = this.findLineNumberPoligon({ poligono, link_lote })
    poligono.splice(index, 1)
  }
  fillAllLotesInfo = async function() {
    const project = {
      "NUMERO DO CONTRIBUINTE": 1,
      "AREA DO TERRENO": 1,
      "CODLOG DO IMOVEL": 1,
      "NUMERO DO CONDOMINIO": 1,
      "TESTADA PARA CALCULO": 1,
      "NOME DE LOGRADOURO DO IMOVEL": 1
    }
    const quadra = this.#register.quadra
    const quadraInfo = this.extractQuadraInfo(this.#fields, quadra)
    const iptu = await (new iptus()).getIPTU({ project, info: { setor: quadraInfo.setor, quadra: quadraInfo.quadra }, register: this.#register })
    const vtoCurrentDataId = (new vtos()).lastDataId
    const vto = (await universal.getGenericRegisters({ company: 'public', module: 'data', dataId: vtoCurrentDataId, query: { sq: { $in: [`${quadraInfo.setor}${quadraInfo.quadra}`] } }, project: { sq: 1, codlog: 1, valor: 1 } })).data
    this.#register.lotes.map(loteLine => {
      const link_lote = loteLine.link_lote
      const condominio = link_lote?.substringBetween('(', ')')
      this.fillLoteInfo({ _id: loteLine.lote, vmodel: loteLine })
      const { digito, areaIPTU, contribuinte, codlog, testada, vtoValor } = this.extractIPTUInfo({ iptu, vto, setor: quadraInfo.setor, quadra: quadraInfo.quadra, lote: loteLine.link_lote, condominio })
      loteLine.digito = digito
      loteLine.areaIPTU = areaIPTU
      loteLine.contribuinte = contribuinte
      loteLine.codlog = codlog
      loteLine.testada = testada
      loteLine.valorOutorga = vtoValor
    })
  }

  // ----------------------------------------------------------------------------------------------------------

  fillLoteInfo = async function({ _id, vmodel }) {
    const lote = await this.getLotes({ action: 'read/one', query: { _id }, project: { setor: 1, quadra: 1, lote: 1, condominio: 1 } })
    const iptu = await (new iptus()).getIPTU({ action: 'read/one', project: { "NUMERO DO CONTRIBUINTE": 1, "AREA DO TERRENO": 1, "CODLOG DO IMOVEL": 1, "TESTADA PARA CALCULO": 1 }, info: lote, register: this.#register })
    if (iptu) {
      vmodel.digito = (lote.lote && lote.lote != '0000') ? iptu["NUMERO DO CONTRIBUINTE"].split('-')[1] : ''
      vmodel.areaIPTU = jsf.numberOut(iptu["AREA DO TERRENO"] || '0', 2)
      vmodel.contribuinte = (lote.lote && lote.lote != '0000') ? iptu["NUMERO DO CONTRIBUINTE"].replace(/^(\d{3})(\d{3})(\d{4})*/, '$1.$2.$3') : ''
      // tegra não usa a linha acima porque não tem a coluna contribuinte no multilines
      vmodel.codlog = iptu["CODLOG DO IMOVEL"]
      vmodel.testada = iptu["TESTADA PARA CALCULO"] ? jsf.numberOut(iptu["TESTADA PARA CALCULO"], 2) : ''
      const _codlog = iptu["CODLOG DO IMOVEL"].replace('-', '')
      const sq = `${lote.setor}${lote.quadra}`
      const vtoCurrentDataId = (new vtos()).lastDataId
      const _vtos = (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: vtoCurrentDataId, query: { sq: sq, codlog: _codlog }, project: { valor: 1 } }), returnData: true })).data
      if (Array.isArray(_vtos) && _vtos[0]) { vmodel.valorOutorga = _vtos[0].valor }
    }
  }
  extractQuadraInfo = function(fields, _quadra) {
    let fieldQuadra = jsf.getJSONObject({ fields, name: 'quadra' })
    const quadraText = fieldQuadra.options.find(option => option.option.value == _quadra).option.text
    const tipo = quadraText.split(' | ')[0]
    const [setor, quadra] = quadraText.split(' | ')[1].split(' (')[0].split('.')
    return { setor, quadra, tipo }
  }
  extractIPTUInfo = function({ iptu, vto, setor, quadra, lote, condominio }) { // remover função do mapfunctions_public
    const _iptu = (lote != '0000')
      ? iptu?.find(register => register["NUMERO DO CONTRIBUINTE"].startsWith(`${setor}${quadra}${lote}-`))
      : iptu?.find(register => register["NUMERO DO CONTRIBUINTE"].startsWith(`${setor}${quadra}`) && register["NUMERO DO CONDOMINIO"].startsWith(`${condominio}-`))
    const digito = (lote != '0000' && _iptu && _iptu["NUMERO DO CONTRIBUINTE"]) ? _iptu["NUMERO DO CONTRIBUINTE"].split('-')[1] : ''
    const areaIPTU = (_iptu && _iptu["AREA DO TERRENO"]) ? `${_iptu["AREA DO TERRENO"]},00` : ''
    const contribuinte = (lote != '0000' && _iptu && _iptu["NUMERO DO CONTRIBUINTE"]) ? _iptu["NUMERO DO CONTRIBUINTE"].replace(/^(\d{3})(\d{3})(\d{4})*/, '$1.$2.$3') : ''
    const codlog = (_iptu && _iptu["CODLOG DO IMOVEL"]) ? _iptu["CODLOG DO IMOVEL"] : ''
    const testada = (_iptu && _iptu["TESTADA PARA CALCULO"]) ? _iptu["TESTADA PARA CALCULO"] : ''
    const logradouro = (_iptu && _iptu["NOME DE LOGRADOURO DO IMOVEL"]) ? _iptu["NOME DE LOGRADOURO DO IMOVEL"] : ''
    const _vto = vto?.find(register => register.codlog == codlog.replace('-', ''))
    const vtoValor = (_vto && _vto.valor) ? _vto.valor : ''
    return { digito, areaIPTU, contribuinte, codlog, testada, logradouro, vtoValor }
  }
  conditionalShowOnMap = function(array) {
    if (Array.isArray(this.#polygonsOnMap[array]) && this.#polygonsOnMap[array].length > 0) {
      this.#polygonsOnMap[array].map(polygon => polygon.setMap(null))
      this.#polygonsOnMap[array] = []
      return false
    }
    return true
  }
  getQuadraInfo = function() {
    const quadra = this.#register.link_quadra
    if (!quadra) {
      window.hideOverlay()
      return window.showWarning({ text: 'Selecione uma quadra!', dismissible: true })
    }
    if (!this.#register.poligono[0]?.info?.Lote) { this.#register.poligono = [] }
    const _tipo = quadra.split(' | ')[0]
    const [_setor, _quadra] = quadra.split(' | ')[1].split(' (')[0].split('.')
    return { tipoQuadra: _tipo, setor: _setor, quadra: _quadra }
  }
  showLotesPoligonosOnMap = async function() {
    window.showOverlay()
    if (!this.conditionalShowOnMap('lotes')) { return window.hideOverlay() }
    // ---
    // const { tipoQuadra, setor, quadra } = this.getQuadraInfo()
    // const lotes = await this.getLotes({ query: { tipoQuadra, setor, quadra }, project: { lote: 1, condominio: 1, poligono: 1, sqlc: 1 } })
    const quadra = this.#register.link_quadra
    if (!quadra) {
      window.hideOverlay()
      return window.showWarning({ text: 'Selecione uma quadra!', dismissible: true })
    }
    if (!this.#register.poligono[0]?.info?.Lote) { this.#register.poligono = [] }
    const _tipo = quadra.split(' | ')[0]
    const [_setor, _quadra] = quadra.split(' | ')[1].split(' (')[0].split('.')
    const lotes = await this.getLotes({ query: { tipoQuadra: _tipo, setor: _setor, quadra: _quadra }, project: { lote: 1, condominio: 1, poligono: 1, sqlc: 1 } })
    // ---
    this.showLotesInMap(lotes)
    window.hideOverlay()
  }
  getLotes = async function({ dataId, action, query, project }) {
    return (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: dataId || this.#lotesCurrentDataId, action, query, project }), returnData: true })).data
  }
  getColorStatusLote = async function({ status }) {
    return (await jsf.getFunc({ func: () => universal.getGenericRegisters({ dataId: 'lotes_status', query: { _id: status }, project: { color: 1 } }), returnData: true })).data
  }
  setColorOnLote = async function({ link_status, color, poligono, link_lote }) {
    const index = this.findLineNumberPoligon({ poligono, link_lote })
    if (index == -1) return
    poligono[index].options = { fillColor: color, fillOpacity: 0.7 }
    poligono[index].info.status = link_status
    const lotePoligono = poligono[index]
    this.removerPoligonoLote({ poligono, link_lote })
    poligono.push(lotePoligono)
  }
  findLineNumberPoligon = function({ poligono, link_lote }) {
    const findId = element => element.info?.Lote == link_lote ? true : false
    return poligono.findIndex(findId)
  }
  adicionarLoteNoMapa = async function({ lote, link_lote, poligono, status }) {
    if (!lote) { return window.$showAlert({ type: 'warning', text: 'Selecione um lote!', dismissible: true }) }
    const index = this.findLineNumberPoligon({ poligono, link_lote })
    if (index != -1) { return window.$showAlert({ type: 'warning', text: 'Lote já estava no mapa!', dismissible: true }) }
    const lotePoligono = await this.getLotes({ query: { _id: lote }, project: { poligono: 1 } })
    const lotePoligonoAndInfo = { info: { Lote: link_lote }, poligono: lotePoligono[0].poligono[0] }
    if (status) {
      const statusLote = await this.getColorStatusLote({ status })
      lotePoligonoAndInfo.options = { fillColor: statusLote[0].color, fillOpacity: 0.77 }
    }
    poligono.push(lotePoligonoAndInfo)
  }
  showLotesInMap = async function(lotes) {
    // está dando problema ao plotar os lotes após uma atualização de versão não gravada.
    lotes?.map(lote =>  this.#newLotesMap.insertObject(new LotePoligono({
      register: this.#register,
      fields: this.#fields,
      polygonsAux: this.#polygonsAux,
      lotesCurrentDataId: this.#lotesCurrentDataId,
      lotesLastDataId: this.#lotesLastDataId,
      LDVM: this.#_LDVM,
      newQuadraSelect: this.#newQuadraSelect,
      newLotesMultilines: this.#newLotesMultilines,
      iptus: this.#_iptus,
      data: lote,
      coords: lote.poligono?.[0] || [],
      lotesPolygonsOptions: this.#lotesPolygonsOptions
    })))
  }
}

class lotesMap extends map {
  #poligono
  #newLotesMultilines
  #updateInfo
  #lotesCanotUpdate
  #lotesToUpdate
  constructor({ fields, div, poligono, newLotesMultilines }) {
    const key = poligono?.[0]?.info ? 'poligonsComInfo' : 'poligonsSemInfo'
    super({
      field: jsf.getJSONObject({ fields, name: 'poligono' }),
      div,
      [key]: poligono
    })
    this.#poligono = poligono
    this.#newLotesMultilines = newLotesMultilines
    this.#updateInfo = newLotesMultilines.updateInfo
      .map(info => { return {
        ...info,
        status: Array.isArray(info.data?.poligono?.[0]) && info.data?.poligono?.[0].length > 0,
        poligonUpdate: this.#checkPoligonUpdate(info)
      } })
    this.#lotesCanotUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => !info.status) })
    this.#lotesToUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => info.poligonUpdate) })
  }
  #checkPoligonUpdate(info) { return JSON.stringify(info.data?.poligono?.[0]) != JSON.stringify(this.#poligono?.[info.lineIndex].poligono) }
  get isDataIdUpdated() { return this.#newLotesMultilines.isDataIdUpdated }
  get canUpdateDataId() { return !this.#updateInfo.some(info => info.status == false) }
  updateDataId = () => this.#updateInfo?.filter(info => info.poligonUpdate)?.forEach(info => this.#poligono[info.lineIndex].poligono = info.data?.poligono?.[0])
  cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getPropList('oldLabel')} não possuem polígonos!` : ''
  // cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getRegisterPropList(register, 'link_lote')} não possuem polígonos!` : ''
  sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getPropList('oldLabel')} tiveram alterações nos polígonos!` : ''
  // sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getRegisterPropList(register, 'link_lote')} tiveram alterações nos polígonos!` : ''
}
class lotesMultilines extends multilines {
  #fields
  #dataId
  #register
  #lastDataId
  #updateInfo
  #newQuadraSelect
  #lotesCurrentDataId
  #iptus
  #lotesCanotUpdate
  #lotesToUpdate
  constructor({ register, fields, dataId, lastDataId, newQuadraSelect, lotesCurrentDataId, iptus }) {
    super({
      field: jsf.getJSONObject({ fields, name: 'lotes' }),
      dataId,
      data: register.lotes
    })
    this.#fields = fields
    this.#dataId = dataId
    this.#register = register
    this.#lastDataId = lastDataId
    this.#newQuadraSelect = newQuadraSelect
    this.#lotesCurrentDataId = lotesCurrentDataId
    this.#iptus = iptus
    this.lote = class line {
      #_id
      constructor({ _id }) {
        this.#_id = _id
      }
      getProp = async (prop) => (await jsf.getFunc({ func: () => universal.getGenericRegisters({
        company: 'public',
        dataId: dataId,
        query: { _id: this.#_id },
        project: { [prop]: 1 },
        action: 'read/one'
      }), returnData: true })).data[prop]
    }
    this.updateLines = this.updateLines.bind(this)
  }
  build = async ({ linkDataVersionsManager }) => {
    const _updateInfo = await this.executeInAllLines({ func: async ({ line, index }) => {
      return {
        lineIndex: index,
        oldId: line.lote,
        oldLabel: line.link_lote,
        ...(await linkDataVersionsManager.canUpdateDataId({
          query: { sqlc: await (new this.lote({ _id: line.lote })).getProp('sqlc') },
          project: { sqlc: 1, poligono: 1 }
        }))
        // ,status: false
      }
    }})
    this.#updateInfo = _updateInfo
    this.#lotesCanotUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => !info.status) })
    this.#lotesToUpdate = new updateInfo({ data: this.#updateInfo?.filter(info => info.status) })
    return this
  }
  get updateInfo() { return this.#updateInfo }
  get isDataIdUpdated() { return (this.#dataId == this.#lastDataId) }
  get canUpdateDataId() { return !this.#updateInfo.some(info => info.status == false) }
  set lines(value) { this.#register.lotes = super.lines = value }
  set lotesCurrentDataId(value) { this.#lotesCurrentDataId = value }
  updateDataId = async () => (await this.#updateInfo).forEach(info => super.lines[info.lineIndex].lote = info.data?._id)
  cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getPropList('oldLabel')} não foram encontrados na nova base de dados!` : ''
  // cannotUpdateText = () => this.#lotesCanotUpdate.notEmpty ? `Os lotes: ${this.#lotesCanotUpdate.getRegisterPropList(this.#register, 'link_lote')} não foram encontrados na nova base de dados!` : ''
  sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getPropList('oldLabel')} foram atualizados!` : ''
  // sucessUpdatedText = () => this.#lotesToUpdate.notEmpty ? `Os lotes: ${this.#lotesToUpdate.getRegisterPropList(this.#register, 'link_lote')} foram atualizados!` : ''
  async updateLines({ lines }) {
    // const newQuadraSelect = new QuadraSelect({ field: jsf.getJSONObject({ fields: this.#lotes.page.Form.$refs.FormLines.fields, name: 'quadra' }) })
    this.fillLotesFieldOptions({ newQuadraSelect: this.#newQuadraSelect })
    this.lines = lines.map(data => { return {
      lote: data._id, link_lote: data.lote, digito: '', areaIPTU: '', contribuinte: '', codlog: '', testada: '', valorOutorga: ''
    } })
    await Promise.all(super.lines.map(async (line, index) => await this.fillLoteInfo({ _id: line.lote, vmodel: this.#register.lotes[index] })))
    this.#register.poligono = lines.map(data => { return {
      info: { Lote: data.lote }, poligono: data.poligono[0]
    } })
    this.reCalculeFieldsByChangeLotes()
  }
  async fillLotesFieldOptions({ newQuadraSelect }) {
    if (!this.#register.quadra) { return }
    // const quadraInfo = this.extractQuadraInfo(page.Form.$refs.FormLines, register.quadra)
    const quadraInfo = newQuadraSelect.extractInfo()
    // const fieldLotes = jsf.getJSONObject({ fields: FormLines.fields, name: 'lote', multilines: 'lotes' })
    const fieldLotes = jsf.getJSONObject({ fields: this.#fields, name: 'lote', multilines: 'lotes' })
    // fieldLotes.loading = FormLines.styles["--over-color"]
    let options = []
    const lotes = await this.getLotes({
      query: {
        tipoQuadra: quadraInfo.tipo,
        setor: quadraInfo.setor,
        quadra: quadraInfo.quadra
      },
      project: { lote: 1, condominio: 1 }
    })
    options = lotes.map(e => ({ option: { text: `${e.lote}${(e.condominio && e.condominio != '00') ? ' (' +  e.condominio + ')' : ''}`, value: e._id } })).sort((a, b) => a.option.text.localeCompare(b.option.text))
    options.unshift({ "option": { "text": "", "value": "" } })
    fieldLotes.options = options
    fieldLotes.loading = 'false'
    // const fieldRestricoes = jsf.getJSONObject({ fields: FormLines.fields, name: 'lote', multilines: 'restricoes' })
    // fieldRestricoes.loading = FormLines.styles["--over-color"]
    // fieldRestricoes.options = options
    // fieldRestricoes.loading = 'false'
  }
  async fillLoteInfo({ _id, vmodel }) {
    const lote = await this.getLotes({ action: 'read/one', query: { _id }, project: { setor: 1, quadra: 1, lote: 1, condominio: 1 } })
    const iptu = await this.#iptus.getIPTU({ action: 'read/one', project: { "NUMERO DO CONTRIBUINTE": 1, "AREA DO TERRENO": 1, "CODLOG DO IMOVEL": 1, "TESTADA PARA CALCULO": 1 }, info: lote, register: this.#register })
    if (iptu) {
      vmodel.digito = (lote.lote && lote.lote != '0000') ? iptu["NUMERO DO CONTRIBUINTE"].split('-')[1] : ''
      vmodel.areaIPTU = jsf.numberOut(iptu["AREA DO TERRENO"] || '0', 2)
      vmodel.contribuinte = (lote.lote && lote.lote != '0000') ? iptu["NUMERO DO CONTRIBUINTE"].replace(/^(\d{3})(\d{3})(\d{4})*/, '$1.$2.$3') : ''
      // tegra não usa a linha acima porque não tem a coluna contribuinte no multilines
      vmodel.codlog = iptu["CODLOG DO IMOVEL"]
      vmodel.testada = iptu["TESTADA PARA CALCULO"] ? jsf.numberOut(iptu["TESTADA PARA CALCULO"], 2) : ''
      const _codlog = iptu["CODLOG DO IMOVEL"].replace('-', '')
      const sq = `${lote.setor}${lote.quadra}`
      const vtoCurrentDataId = (new vtos()).lastDataId
      const _vtos = (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: vtoCurrentDataId, query: { sq: sq, codlog: _codlog }, project: { valor: 1 } }), returnData: true })).data
      if (Array.isArray(_vtos) && _vtos[0]) { vmodel.valorOutorga = _vtos[0].valor }
    }
  }
  reCalculeFieldsByChangeLotes() {
    new iptus().calculeSumAreaIPTU({ register: this.#register, fields: this.#fields })
    new vtos().calculeMaxVTO({ fields: this.#fields, lotes: this.#register.lotes })
    // this.calculeTestadas(register)
  }
  async getLotes({ dataId, action, query, project }) {
    return (await jsf.getFunc({ func: () => universal.getGenericRegisters({
      company: 'public',
      dataId: dataId || this.#lotesCurrentDataId,
      action,
      query,
      project
    }), returnData: true })).data
  }
}
class LotePoligono extends Poligono {
  #register
  #fields
  #polygonsAux
  #newLotesMultilines
  #lotesPolygonsOptions
  #updateInfo
  constructor({ register, fields, polygonsAux, newLotesMultilines, data, coords, lotesPolygonsOptions, updateInfo }) {
    super({ data, coords })
    this.#register = register
    this.#fields = fields
    this.#polygonsAux = polygonsAux
    this.#newLotesMultilines = newLotesMultilines
    this.#lotesPolygonsOptions = lotesPolygonsOptions
    this.#updateInfo = updateInfo || []
  }
  get events() { return [
    {
      obj: '#infoWindow_cadastrarLote',
      event: 'onclick',
      action: async ({ infoWindow, data }) => {
        window.showOverlay()
        this.#register.lotes.push({ lote: data._id, link_lote: data.lote, digito: '', areaIPTU: '', contribuinte: '', codlog: '', testada: '', valorOutorga: '' })
        await this.#newLotesMultilines.fillLoteInfo({ _id: data._id, vmodel: this.#register.lotes.getLast() })
        this.#register.poligono.push({ poligono: data.poligono[0], info: { Lote: data.lote } })
        this.#newLotesMultilines.reCalculeFieldsByChangeLotes()
        window.hideOverlay()
        infoWindow.close()
      }
    },
    {
      obj: '#infoWindow_usarEndereco',
      event: 'onclick',
      action: async ({ infoWindow, data }) => {
        window.showOverlay()
        const iptuCurrentDataId = (new iptus()).getCurrentDataId({ register: this.#register })
        const iptu = (await jsf.getFunc({ func: () => universal.getGenericRegisters({ company: 'public', dataId: iptuCurrentDataId, query: { sqlc: data.sqlc }, project: { "NOME DE LOGRADOURO DO IMOVEL": 1, "NUMERO DO IMOVEL": 1 }, action: 'read/one' }), returnData: true })).data
        this.#register.endereco = iptu ? `${iptu?.["NOME DE LOGRADOURO DO IMOVEL"]} ${iptu?.["NUMERO DO IMOVEL"]}` : this.#register.endereco
        infoWindow.close()
        window.hideOverlay()
      }
    },
    {
      obj: '#infoWindow_selecionarLote',
      event: 'onclick',
      action: async ({ object, infoWindow, data }) => {
        window.showOverlay()
        this.#polygonsAux.push(data)
        object.setOptions(this.#lotesPolygonsOptions('selectedAux'))
        infoWindow.close()
        window.hideOverlay()
      }
    },
    {
      obj: '#infoWindow_desselecionarLote',
      event: 'onclick',
      action: async ({ object, infoWindow, data }) => {
        window.showOverlay()
        this.#polygonsAux.splice(this.#polygonsAux.findIndex(polygon => polygon._id == data._id), 1)
        object.setOptions(this.#lotesPolygonsOptions('notSelected'))
        infoWindow.close()
        window.hideOverlay()
      }
    },
    {
      obj: '#infoWindow_removerLote',
      event: 'onclick',
      action: ({ object, infoWindow }) => {
        object.setMap(null)
        infoWindow.close()
      }
    }
  ] }
  options() {
    return this.#updateInfo.some(info => info.data?._id == this.data._id)
      ? this.#lotesPolygonsOptions('selectedAux')
      : this.#lotesPolygonsOptions('notSelected')
  }
  info() {
    return `<h3 class="infowindowTitle">Lote</h3><h3>${this.data.lote || '-'}</h3>
      <div class="infoBlock">
          <ul>
            <li><label>Condomínio:</label> ${this.data.condominio || '-'}</li>
          </ul>
      </div>
      <div class="infoButtons">
        ${!this.#polygonsAux.length ? '<button type="button" id="infoWindow_cadastrarLote">Cadastrar</button>' : ''}
        ${!this.#polygonsAux.length ? '<button type="button" id="infoWindow_removerLote">Remover</button>' : ''}
        ${!this.#polygonsAux.length ? '<button type="button" id="infoWindow_usarEndereco">Usar Endereço</button>' : ''}
        ${this.#polygonsAux.length && !this.#polygonsAux.some(polygon => polygon._id == this.data._id) ? '<button type="button" id="infoWindow_selecionarLote">Selecionar</button>' : ''}
        ${this.#polygonsAux.length && this.#polygonsAux.some(polygon => polygon._id == this.data._id) ? '<button type="button" id="infoWindow_desselecionarLote">Desselecionar</button>' : ''}
      </div>`
  }
}